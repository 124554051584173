<template>
   <div v-if="view == 'Grid'" class="card rounded-0 border-0" :class="'content-'+ index">
      <img :src="noticia.banner == null ? '' : noticia.banner" alt="noticia" @error="imageError" class="card-img">
      <router-link :to="'/noticia/'+ noticia.id">
         <div class="card-img-overlay p-0 d-flex align-items-end">
            <div class="row m-0 w-100">
               <div class="w-100 row m-0 p-12">
                  <h1 class="limitador-2 font-16 mb-2">{{ noticia.titulo }}</h1>
                  <p class="card-text limitador-3 postagem-html text-white" v-html="noticia.descricao"></p>
               </div>
               <div class="w-100 progress p-0 rounded-0 bg-transparent" v-if="index == 0">
                  <div class="progress-bar bg-theme" :style="'width: '+ timerBlog +'%'" role="progressbar"></div>
               </div>
            </div>
         </div>
      </router-link>
   </div>

   <div v-else-if="view == 'Card'" class="blog__item bg-white cursor-pointer mb-30">
      <div class="blog__thumb fix">
         <router-link :to="'/noticia/'+ noticia.id">
            <img :src="noticia.banner == null ? '' : noticia.banner" alt="noticia" @error="imageError">
         </router-link>
      </div>
      <div class="blog__content py-12">
         <h3 class="limitador"><router-link :to="'/noticia/'+ noticia.id">{{ noticia.titulo }}</router-link></h3>
         <div class="blog__meta">
            <span>{{ noticia.data.dayOfMonth.toString().padStart(2, '0') +'/'+ noticia.data.monthValue.toString().padStart(2, '0') +'/'+ noticia.data.year }}</span>
            <span> {{ noticia.data.hour.toString().padStart(2, '0') +':'+ noticia.data.minute.toString().padStart(2, '0') }}</span>
         </div>
         <p class="limitador-3 postagem-html" v-html="noticia.descricao"></p>
      </div>
   </div>

   <div v-else-if="view == 'Linha'" class="col-12 px-0">
      <div class="card mb-1">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="wpx-350 mx-auto">
                  <router-link :to="'/noticia/'+ noticia.id" class="w-img">
                     <img :src="noticia.banner == null ? '' : noticia.banner" alt="noticia" class="rounded" @error="imageError">
                  </router-link>
               </div>
               <router-link :to="'/noticia/'+ noticia.id" class="col-md blog__content px-4 mx-xxl-2">
                  <h1 class="limitador color-theme font-18 mb-1">{{ noticia.titulo }}</h1>
                  <div class="blog__meta">
                     <span>{{ noticia.data.dayOfMonth.toString().padStart(2, '0') +'/'+ noticia.data.monthValue.toString().padStart(2, '0') +'/'+ noticia.data.year }}</span>
                     <span> {{ noticia.data.hour.toString().padStart(2, '0') +':'+ noticia.data.minute.toString().padStart(2, '0') }}</span>
                     <ul class="d-flex mt-1">
                        <li class="me-3">
                           <a :href="'https://www.facebook.com/sharer/sharer.php?u='+ urlBase +'/noticia/'+ noticia.id" target="_blank">
                              <i class="fab fa-facebook-square text-facebook font-20"></i>
                           </a>
                        </li>
                        <li class="me-3">
                           <a :href="'https://wa.me/?text='+ urlBase +'/noticia/'+ noticia.id" target="_blank">
                              <i class="fab fa-whatsapp-square text-success font-20"></i>
                           </a>
                        </li>
                        <li>
                           <a :href="'mailto:?subject='+ noticia.titulo +' em CCM do Brasil &amp;body=Saiba mais em nosso site '+ urlBase +'/noticia/'+ noticia.id" target="_blank">
                              <i class="fas fa-envelope font-20"></i>
                           </a>
                        </li>
                     </ul>
                  </div>
                  <p class="limitador-3 postagem-html" v-html="noticia.descricao"></p>
               </router-link>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
   name: 'Noticia',
   props: ['noticia', 'view', 'index'],
   data: function () {
      return {
         urlBase: window.location.origin
      }
   },
   computed: {
		... mapState({
         timerBlog: state => state.timerBlog
		})
	},
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      }
   }
}

</script>

<style scoped>

.card-img {
   object-fit: cover;
   object-position: center;
   height: 100%;
   width: 100%;
}

.card-img-overlay {
   background: #0006;
}

.card-img-overlay * {
   color: #fff;
}

img:not(.card-img) {
	aspect-ratio: 3/2;
	object-fit: cover;
}

.blog__item p {
   height: 72px;
}

.progress,
.progress-bar {
   height: 5px;
   transition: .15s !important;
}

.card.rounded-0 * {
   border-radius: 0 !important;
}

</style>